var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-vertical",
    [
      _c("router-view"),
      _vm.showCustomizer
        ? _c("app-customizer", {
            attrs: { slot: "customizer" },
            slot: "customizer"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }